<template>
  <div>
    <VRow
      :justify="desktopBreakpoints ? 'space-between' : 'start'"
      class="flex-nowrap"
      no-gutters
    >
      <VCol
        :cols="includeIfNotAnswered ? 10 : 12"
        class="d-flex flex-nowrap"
      >
        <div
          v-for="(answer, i) in answers"
          :key="answer.id"
          :style="{ width : `${itemWidth}%` }"
          :class="[
            textAligment(i),
            {
              'mr-3' : i !== answers.length - 1 && desktopBreakpoints,
              'mr-2' : i !== answers.length - 1 && !desktopBreakpoints
            }
          ]"
        >
          <VBtn
            depressed
            data-test="tt-btn"
            width="100%"
            min-width="0"
            :height="desktopBreakpoints ? 54 : 46"
            :ripple="$vuetify.breakpoint.lgAndUp"
            class="text-center mb-2 px-0"
            :class="getButtonColor(answer.id, i)"
            @click="handleAnswer(answer)"
          >
            <span
              v-if="visualizationType === 'digits'"
              class="tt-black--text text-h6"
              :class="{ 'tt-white--text' : answer.id === currentAnswerId }"
            >
              {{ i + 1 }}
            </span>
            <VIcon
              v-else-if="visualizationType === 'smiles'"
              size="28"
              :color="answer.id === currentAnswerId ? 'tt-white' : getSmile(i).color"
            >
              {{ getSmile(i).icon }}
            </VIcon>
            <VIcon
              v-else-if="visualizationType === 'dots'"
              size="6"
              :color="answer.id === currentAnswerId ? 'tt-white' : 'tt-black'"
            >
              $circleSolid
            </VIcon>
          </VBtn>

          <div
            v-if="titleVision(answer, i)"
            class="text-body-2 tt-black--text text--lighten-1"
            :class="styleMarginsOnMobile(i)"
          >
            {{ answer.text }}
          </div>
        </div>
      </VCol>

      <VCol
        v-if="includeIfNotAnswered"
        class="text-center ml-2"
        cols="2"
      >
        <VBtn
          data-test="tt-btn"
          fab
          :width="desktopBreakpoints ? 54 : 46"
          :height="desktopBreakpoints ? 54 : 46"
          depressed
          class="mb-2"
          :ripple="$vuetify.breakpoint.lgAndUp"
          :color="cantAnswerOption.id === currentAnswerId ? 'tt-red' : 'tt-black lighten-6'"
          :class="cantAnswerOption.id === currentAnswerId ? 'tt-white--text' : 'tt-black--text'"
          @click="handleAnswer(cantAnswerOption)"
        >
          <VIcon size="20">
            $close
          </VIcon>
        </VBtn>

        <div class="text-body-2 tt-black--text text--lighten-1">
          {{ cantAnswerOption.text }}
        </div>
      </VCol>
    </VRow>
    <VRow v-if="commentable">
      <VCol cols="12">
        <h2 class="text-h6 mb-6 font-weight-medium">
          {{ $t('commentOnYourChoice') }}
        </h2>
        <VTextarea
          v-model.trim="commentText"
          class="custom-textarea"
          no-resize
          solo
          flat
          hide-details
          height="96"
          placeholder="Текст"
          background-color="tt-black lighten-6"
          @input="debouncedCommentHandler"
        />
      </VCol>
    </VRow>
  </div>
</template>

<script>
import smileList from '@/helpers/smileList';
import debounce from 'lodash/debounce';

export default {
  name: 'QuestionHorizontal',
  props: {
    answers: {
      type: Array,
      default: () => [],
    },
    includeIfNotAnswered: {
      type: Boolean,
      default: false,
    },
    visualizationType: {
      type: String,
      default: '',
    },
    userAnswer: {
      type: Object,
      default: () => ({}),
    },
    commentable: {
      type: Boolean,
      default: false,
    },
    comment: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      smileList,
      cantAnswerOption: {
        id: 'notAnswered',
        text: this.$t('cantRate'),
      },
      currentAnswerId: null,
      commentText: '',
    };
  },
  computed: {
    desktopBreakpoints() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    itemWidth() {
      const width = Math.round(100 / this.answers.length);
      return Number.isFinite(width) ? width : '0';
    },
    isVisualizationTypeSmiles() {
      return this.visualizationType === 'smiles';
    },
  },
  created() {
    this.debouncedCommentHandler = debounce(this.commentHandler, 250);
    if (Object.keys(this.userAnswer).length) {
      this.currentAnswerId = this.userAnswer.answerId;
      if (this.userAnswer.answerId === null && this.userAnswer.notAnswered) {
        this.currentAnswerId = this.cantAnswerOption.id;
      }
    }
    if (this.comment?.length) {
      this.commentText = this.comment;
    }
  },
  methods: {
    handleAnswer(answer) {
      if (answer.id === this.currentAnswerId) {
        this.currentAnswerId = null;
        this.$emit('delete-answer');
        return;
      }
      this.currentAnswerId = answer.id;
      const id = this.currentAnswerId;
      const payload = {};
      // Если выбран вариант "Не могу оценить", то ничего не отправляем
      if (id === 'notAnswered') {
        payload.notAnswered = true;
      } else {
        payload.answerId = id;
      }
      if (this.commentText.length) payload.comment = this.commentText;
      this.$emit('answer', payload);
    },
    titleVision(answer, index) {
      if (!this.currentAnswerId) return index === 0 || index === this.answers.length - 1;
      return answer.id === this.currentAnswerId;
    },
    textAligment(i) {
      if (i === 0) return 'text-left';
      if (i === this.answers.length - 1) return 'text-right';
      return 'text-center';
    },
    styleMarginsOnMobile(idx) {
      if (idx === 0) return 'mr-n12';
      if (idx === this.answers.length - 1) return 'd-flex justify-end';
      return 'mx-n6';
    },
    commentHandler() {
      this.$emit('send-comment', this.commentText);
    },
    getButtonColor(id, index) {
      const activeColor = this.isVisualizationTypeSmiles ? this.getSmile(index).color : 'tt-blue';
      return id === this.currentAnswerId ? activeColor : null;
    },
    getSmile(idx) {
      const percentage = idx / (this.answers.length - 1);
      const smile = this.smileList[Math.round((this.smileList.length - 1) * percentage)];
      return smile;
    },
  },
};
</script>

<style scoped lang="scss">
.custom-textarea {
  border: 1px solid map-get($tt-black, 'lighten-5') !important;
  border-radius: 5px !important;
}
</style>
